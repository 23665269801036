.form-container {
  max-width: 500px;
  min-width: 500px; // TODO update widths after testing on mobile
  margin: auto;
  margin-top: 48px;
}

.fields-container {
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.form-container .text-field {
  margin: 18px 0;
}

.form-footer {
  display: flex;
  align-items: center;
  margin-top: 24px;

  span {
    flex-grow: 1;
  }
}

.form-container .heading-text {
  margin: 18px 0;
}
